.contactInfo {
  composes: flex-row from '../../index.css';
  justify-content: space-between;
  margin: 10px;
  /* width: 90%; */
  max-width: 700px;
}

.contact {
  composes: flex-row from '../../index.css';
  align-items: center;
  margin: 0 10px;
}

.contactLabel {
  margin-left: 10px;
}

@media screen and (max-width: 768px) {
  
  .contactInfo {
    display: flex;
    flex-direction: column;
  }
  .contact {
    margin: 0;
  }

  .contactInfo > :not(:last-child) {
    margin-bottom: 10px;
  }

}