.heading {
  composes: flex-col from '../../../index.css';

  margin-bottom: 30px;
  padding: 10px;
}

.heading span {
  font-size: var(--fz-xxxl);
  font-weight: bold;
}

.heading hr {
  background-color: var(--clr-heading-bar);
  border: none;
  width: 70px;
  height: 2px;
}