.navListContainer {
  composes: flex-row from '../../../index.css';
  justify-content: center;
  align-items: center;
  margin-right: 2rem;
}

.navList {
  composes: flex-row from '../../../index.css';
  flex-wrap: wrap;
  list-style: none;
}

.navItem {
  padding: 0.5rem;
  margin: 0 0.5rem;
}

.navItem:hover {
  cursor: pointer;
}

.navList > a {
  text-decoration: none;
  color: var(--fc-primary);
}

@media screen and (min-width: 1200px) {
  .navItem {
    padding: 0.5rem;
    margin: 0 1rem;
  }
}