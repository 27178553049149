.hamburger {
  position: relative;
  height: 15px;
  width: 30px;
  margin: 0 15px;

  cursor: pointer;
  border: none;
  background-color: transparent;
}

.hamburger::before, .hamburger::after {
  content: '';
  position: absolute;
  height: 3px;
  width: 100%;
  border-radius: 1.5px;
  background-color: var(--icon-clr-primary);
  transition: transform 0.5s ease-in-out;
  margin: auto;
  left: 0;
}

.hamburger::before {
 top: 0;
}

.hamburger::after {
  bottom: 0;
}

.hamburger.active::before {
  transform: rotate(45deg);
  bottom: 0;
}

.hamburger.active::after {
  transform: rotate(-45deg);
  top: 0;
}
