.messageBoxContainer {
  composes: flex-row from '../../index.css';
  width: 60%;
  min-height: 600px;
  align-items: center;
  position: relative;
}

.textBox {
  composes: cardContainer from '../../index.css';
  composes: flex-col from '../../index.css';
  background-color: var(--bg-contact-section-box);
  align-items: center;
  justify-content: center;
  text-align: center;

  padding: 10px;
  position: absolute;

  width: 30%;
  height: 250px;
  max-width: 350px;
  z-index: 10;
}

.mainText {
  font-size: var(--fz-xxxl);
  font-weight: bold;
  margin-bottom: 20px;
}

.secondaryText {
  font-size: var(--fz-lg);
  font-weight: bold;
}

.inputBoxContainer {
  composes: cardContainer from '../../index.css';
  composes: flex-row from '../../index.css';

  position: absolute;
  width: 80%;
  background-color: var(--bg-card-primary);
  justify-content: center;
  right: 0px;

  padding: 20px;
  width: 80%;
}

.inputBox {
  composes: flex-col from '../../index.css';
  width: 70%;
  max-width: 450px;
  margin: 20px 10px;
}

.fullNameContainer, .emailContainer, .messageContainer {
  composes: flex-col from '../../index.css';
  margin-bottom: 20px;
}

.labelContainer {
  composes: flex-row from '../../index.css';
  margin-bottom: 5px;
}
.label {
  font-size: var(--fz-lg);
}

.error {
  font-size: var(--fz-md);
  margin-left: 10px;
  color: red;
}

.emailInput, .fullNameInput {
  height: 30px;
}

.emailInput, .fullNameInput, .messageTextarea {
  border-radius: 5px;border: none;
  border: solid 1px gray;
  background-color: var(--bg-input-messagebox);
  color: var(--bg-fc-messagebox);
}

.messageTextarea {
  height: 150px;
  resize: none;
}

.submitContainer {
  composes: flex-col from '../../index.css';
  /* justify-content: flex-end; */
}

.submitButton {
  width: 100px;
  height: 45px;
  border-radius: var(--bdr-radius-md);
  background-color: var(--bg-submit-msg-btn);
  box-shadow: 0.5px 0.5px 1px rgb(0, 0, 0, 0.50);
  flex-shrink: 0;
}

.submitButton:hover {
  cursor: pointer;
  background-color: var(--bg-submit-msg-btn-hover);
}

.submitButton:active {
  cursor: pointer;
  background-color: var(--bg-submit-msg-btn);
}

.serverError {
  margin-top: 10px;
  color: red;
  font-size: var(--fz-sm);
}

.confirmationText {
  margin-top: 5px;
  flex-shrink: 1;
  margin-left: 10px;
  color: green;
  font-style: italic;
  font-size: var(--fz-sm);
}

@media screen and (max-width: 768px) {
  .messageBoxContainer {
    width: 80%;
    margin-bottom: 120px;
    /* position: unset; */
    justify-content: center;
  }

  .textBox {
    height: 100px;
    width: 70%;
    max-width: unset;
    top: 15%;
    left: unset;
  }

  .inputBoxContainer {
    right: unset;
    width: 90%;
    top: 22%;
    justify-content: center;
  }

  .inputBox {
    margin: 70px 0 0 0;
    width: 90%;
  }

  .labelContainer {
    flex-direction: column;  
  }
  .error {
    margin: 0 0 5px 5px;
  }
}

@media screen and (max-width: 550px) {
  .messageBoxContainer {
    width: 100%;
    margin-bottom: 120px;
    /* position: unset; */
    justify-content: center;
  }
}