.dark {

  --clr-heading-bar: var(--clr-white-600);

  --bg-body: var(--clr-black-800);
  --bg-header: var(--clr-blue-700);
  --bg-navbar-overlay: var(--clr-black-800);
  --bg-footer: var(--clr-blue-700);
  --bg-wave-border: var(--clr-black-720);

  --bg-home-primary: var(--clr-black-800);
  --bg-main-primary: var(--clr-black-800);
  --bg-main-secondary: var(--clr-black-720);
  --bg-resume-download-btn: var(--clr-blue-700);
  --bg-resume-download-btn-hover: var(--clr-blue-800);
  
  --bg-card-primary: var(--clr-black-760);
  --bg-skill-card: var(--clr-black-800);
  --bg-card-secondary: var(---clr-blue-700);
  --bg-skill-pill: var(--clr-black-760);
  --bg-btn-primary: var(--clr-gray-700);
  
  --bg-contact-bar: var(--clr-black-780);
  --bg-experience-section: var(--clr-black-720);
  --bg-exp-card-primary: var(--clr-black-760);
  --bg-exp-card-secondary: var(--clr-blue-700);
  --bg-contact-section-box: var(--clr-blue-700);

  --bg-submit-msg-btn: var(--clr-gray-800);
  --bg-submit-msg-btn-hover: var(--clr-gray-700);
  --bg-submit-msg-btn-brdr: var(--clr-gray-700);
  --bg-input-messagebox: var(--clr-gray-700);
  --bg-fc-messagebox: var(--clr-white-900);

  --bg-pill: var(--clr-black-720);
  --fc-primary: var(--clr-white-600);
  --fc-secondary: var(--clr-blue-700);
  --icon-clr-primary: var(--clr-gray-100);
}

.light {
  --clr-heading-bar: var(--clr-black-900);

  --bg-body: var(--clr-white-900);
  --bg-header: var(--clr-blue-500);
  --bg-navbar-overlay: var(--clr-black-900);
  --bg-footer: var(--clr-blue-500);
  --bg-wave-border: var(--clr-gray-400);
  
  --bg-home-primary: var(--clr-white-900);
  --bg-main-primary: var(--clr-white-900);
  --bg-main-secondary: var(--clr-white-900);
  --bg-resume-download-btn: var(--clr-blue-500);
  --bg-resume-download-btn-hover: var(--clr-blue-550);
 
  --bg-card-primary: var(--clr-white-900);
  --bg-skill-card: var(--clr-black-800);
  --bg-card-secondary: var(--clr-blue-500);
  --bg-skill-pill: var(--clr-white-900);
  --bg-btn-primary: var(--clr-gray-300);

  
  --bg-contact-bar: var(--clr-gray-100);
  --bg-experience-section: var(--clr-gray-400);
  --bg-exp-card-primary: var(--clr-white-900);
  --bg-exp-card-secondary: var(--clr-blue-500);
  --bg-contact-section-box: var(--clr-blue-500);

  --bg-submit-msg-btn: var(--clr-gray-400);
  --bg-submit-msg-btn-hover: var(--clr-gray-300);
  --bg-submit-msg-btn-brdr: var(--clr-gray-300);
  --bg-input-messagebox: var(--clr-gray-100);
  --bg-fc-messageboxr: var(--clr-black-900);

  --bg-pill: var(--clr-gray-100);
  --fc-primary: var(--clr-black-900);
  --fc-secondary: var(--clr-blue-500);
  --icon-clr-primary: var(--clr-black-900);
}