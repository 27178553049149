.aboutMeSection {
  background-color: var(--bg-main-primary);
}

.container {
  composes: flex-col from '../../index.css';
  composes: sectionContainer from '../../index.css';
  align-items: center;

  /* padding: 30px; */
  padding-bottom: 100px;
}

.infoContainer {
  composes: flex-row from '../../index.css';
  composes: cardContainer from '../../index.css';
  background-color: var(--bg-card-primary);

  padding: 20px 20px 40px 20px;

  width: max(80%, 370px);
}

.infoContainer > * {
  flex-basis: 100%
}

.aboutMeInfo {
  composes: flex-col from '../../index.css';
  align-items: center;
}

.subHeadingOne, .subHeadingTwo {
  font-size: var(--fz-xxl);
  font-weight: bold;
  margin-bottom: 20px;
}

.infoContent {
  font-size: var(--fz-xl);

  line-height: 2rem;
}

.infoContent > p {
  margin-bottom: 10px;
}

.aboutMeInfo, .skillsInfo {
  margin-top: 30px;
}

.skillsInfo {
  composes: flex-col from '../../index.css';
  align-items: center;

  margin-top: 30px;
}

@media screen and (max-width: 1050px) {

  .infoContainer {
    display: flex;
    flex-direction: column;
  }
}
@media screen and (max-width: 768px) {
  .infoContainer {
    display: flex;
    flex-direction: column;
    width: 90%;
    padding: 15px 15px 20px 15px;
  }

  .aboutMeInfo, .skillsInfo {
    margin-top: 15px;
  }

  .subHeadingOne, .subHeadingTwo {
  font-size: var(--fz-xl);

  }

  .infoContent {
    font-size: var(--fz-lg);
    line-height: 1.8rem;
  }
  .aboutMeInfo {
    padding: unset;
  }

  .skillsInfo {
    margin-top: 20px;
  }
}

@media screen and (max-width: 480px) {
  .infoContainer {
    width: 95%;
  }
  .infoContent {
    font-size: var(--fz-md);
  }
}
