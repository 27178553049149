.navbarSection {
  padding-top: 10px;
  top: 0;
  z-index: 50;
  position: sticky;
}


.navbarContainer {
  composes: flex-row from '../../index.css';
  composes: sectionContainer from '../../index.css';
  padding: 0.8rem 1.2rem;
  justify-content: space-between;

  background-color: var(--bg-header);
  margin: 0 10px 10px 10px;
  border-radius: 20px;
  box-shadow: var(--navbar-shadow);
}

.logoContainer {
  composes: flex-row from '../../index.css';
  align-items: center;
  justify-content: center;
  margin: 0 1.2rem;
}

.logoContainer:hover {
  cursor: pointer;
}

.logo {
  width: 2.7rem;
  height: 2.7rem;
  border-radius: 1.35rem;
  margin-right: 0.8rem;
}

.name {
  font-size: var(--fz-xxl);
  font-weight: bold;
  color: var(--fc-primary);
  font-family: var(--ff-DancingScript);
}

.rightContainer {
  composes: flex-row from '../../index.css';
}

.navListContainer {
  display: flex;
  justify-content: center;
}

.themeSwitchContainer {
  composes: flex-row from '../../index.css';
  align-items: center;
  justify-content: center;

  width: 3.8rem;
}

.burgerIcon {
  display: none;
}

@media screen and (max-width: 768px) {
  .navbarSection {
    /* background-color: unset;
    box-shadow: none; */
  }

  .navbarContainer {
    background-color: unset;
    box-shadow: none;
    padding: 0.2rem 0.2rem;
  }
  .logoContainer, .burgerIcon, .themeSwitchContainer {
    background-color: var(--bg-header);
    height: 3rem;
    border-radius: 1.5rem;
    box-shadow: var(--navbar-shadow);
  }

  .themeSwitchContainer, .burgerIcon {
    width: 3rem;
  }

  .logoContainer {
    margin: 0 1rem 0 0;
  }

  .logo {
    width: 2rem;
    height: 2rem;
    border-radius: 1rem;
    margin-left: 0.8rem;
  }
  .name {
    margin-right: 0.8rem;
    font-size: var(--fz-xl);

  }

  .burgerIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 0.8rem;
    z-index: 100;
    cursor: pointer;
  }

  .navListContainer {
    display: none;
  }
}

