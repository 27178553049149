.container {
  composes: flex-row from '../../index.css';
  composes: cardContainer from '../../index.css';
  overflow: hidden;
  max-width: 800px;
  min-height: 200px;
  background-color: var(--bg-exp-card-primary);

}

.companyInfo {
  composes: flex-col from '../../index.css';
  /* background-color: var(--bg-exp-card-primary); */

  width: 35%;
  padding: 10px;
}

.position {
  font-size: var(--fz-lg);
  font-weight: bold;
}

.companyName {
  font-size: var(--fz-xl);
  font-weight: bold;
}

.location, .tenure {
  font-size: var(--fz-md);
  margin-top: 5px;
}

.workInfo {
  composes: flex-col from '../../index.css';
  background-color: var(--bg-exp-card-secondary);
  padding: 10px;
  width: 60%;
}

.summary {
  margin-bottom: 1em;
  font-size: var(--fz-lg);
}

.summary > * {
  margin-bottom: 0.5em;
  line-height: 1.5rem;
}

.technologies {
  composes: flex-row from '../../index.css';
  flex-wrap: wrap;
}

.technologies > * {
  margin: 5px;
}

@media screen and (max-width: 768px) {
  .container {
    display: flex;
    flex-direction: column;
    width: 80%;
    margin-right: auto;
    margin-left: auto;
  }

  .workInfo {
    width: 100%;
    margin-bottom: 30px;
  }

  .companyInfo {
    width: 100%;
  }

  .summary {
    font-size: var(--fz-md);
  }
}