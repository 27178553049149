.headSection {
  background-color: var(--bg-home-primary); 
  height: 1000px;
}

.container {
composes: flex-row from '../../index.css';
composes: sectionContainer from '../../index.css';
height: 100%;

justify-content: center;
overflow: hidden;
}

.contactBarContainer {
  position: absolute;
  top: 350px;
  left: 0px;
  z-index: 10;
  box-shadow: 0px 1px 5px 1px rgba(0,0,0,0.25);
}

.mainContent {
  composes: flex-row from '../../index.css';
  margin-top: 100px;
  justify-content: center;
}

.infoContainer {
  composes: flex-col from '../../index.css';
  padding: 20px;

  margin-right: 40px;
  margin-top: 80px;

}

.backgroundImage {
  overflow: hidden;
  /* right: 0px;  */
}

.backgroundImage img {
  height: calc(20vw + 200px);
  max-height: 500px;
}

.textInfo {
  composes: flex-col from '../../index.css';
  margin-bottom: 20px;
}

.textInfo > span {
  margin: 5px 0;
}

.subTextOne, .subTextTwo {
  font-size: calc(var(--fz-md) + 1vw);
  color: var(--fc-secondary);
}

.mainText {
  font-size: calc(var(--fz-mega) + 1vw);
  font-weight: bold;
}

.resumeButton {
  width: 150px;
  height: 50px;
  border-radius: var(--bdr-radius-md);
  background-color: var(--bg-resume-download-btn);
}

.resumeButton:hover {
  cursor: pointer;
  background-color: var(--bg-resume-download-btn-hover);
}

.resumeButton:active {
  cursor: pointer;
  background-color: var(--bg-resume-download-btn);
}

.quickLinkContainer {
  composes: flex-row from '../../index.css';
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 970px) {
  .headSection {
    height: 800px;
  }
  .backgroundImage {
    margin-top: 20px;
  }

  .infoContainer {
    margin-top: 20px;
    margin-right: 0;
  }
  .mainContent {
    margin-top: 0;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: flex-end;
    /* justify-content: flex-end; */
  }
  .textInfo {
    text-align: center;
  }
  .subTextOne, .subTextTwo {
    font-size: calc(var(--fz-sm) + 1vw);
    color: var(--fc-secondary);
  }
  
  .mainText {
    font-size: calc(var(--fz-xxxl) + 1vw);
    font-weight: bold;
  }
}
@media  screen and (max-width: 768px) {
  .contactBarContainer {
    display: none;
  }

  .infoContainer {
    margin: 20px 0;
  }
  .backgroundImage {
    margin-top: 20px;
  }
}

