:root {
  --clr-black-900: #000000;
  --clr-white-900: #FFFFFF;
  --clr-gray-900: #393939;
  --clr-gray-500: #CCCCCC;
  --clr-blue-500: #748AFF;
  --clr-blue-550: #6078f9;
  --clr-white-600: #F6F6F6;
  --clr-gray-400: #ECECEC;
  --clr-brown-900: #5D4037;
  --clr-blue-400: #349EFF;
  --clr-blue-600: #35C1F1;
  --clr-gray-blue-900: #37474F;
  --clr-yellow-900: #E2CC1A;
  --clr-orange-900: #EF9201;
  --clr-green-800: #60D94C;
  --clr-green-600: #81C784;
  --clr-purple-800: #A13FCB;
  --clr-purple-900: #A442CD;
  --clr-gray-300: #CECECE;
  --clr-gray-200: #E9E9E9;
  --clr-red-600: #FF4C4C;

  --clr-gray-100: #EBEBEB;
  --clr-black-760: #2D2D2D;
  --clr-black-780: #212121;
  --clr-blue-700: #3E55CD;
  --clr-blue-800: #2641cb;
  --clr-black-750: #2F2F2F;
  --clr-black-800: #1E1E1E;
  --clr-black-720: #252525;
  --clr-black-700: #212121;
  --clr-green-900: #4CAF50;
  --clr-black-850: #171717;
  --clr-gray-800: #414141;
  --clr-gray-700: #555555;

  --fz-xxs: 0.5rem;
  --fz-xs: 0.75rem;
  --fz-sm: 0.875rem;
  --fz-md: 1rem;
  --fz-lg: 1.125rem;
  --fz-xl: 1.3758rem;
  --fz-xxl: 1.625rem;
  --fz-xxxl: 1.825rem;
  --fz-mega: 3rem;

  --sz-media-icons: 2.5rem;

  --container-shadow: 1px 1px 5px 0 rgb(0,0,0,0.25);
  --navbar-shadow: 0 3px 10px 0 rgb(0, 0, 0, 0.25);
  --bdr-radius-md: 5px;
  --bdr-radius-lg: 8px; 

  --ff-roboto: 'Roboto', sans-serif, monospace;
  --ff-DancingScript: 'Dancing Script', cursive;
}

body, ul, p, h1, h2, h3 {
  margin: 0;
  padding: 0;
}

div {
  box-sizing: border-box;
}

button {
  border: none;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.flex-col-center {
  composes: flex-col;
  justify-content: center;
}

.flex-row-center {
  composes: flex-row;
  justify-content: center;
}

.cardContainer {
  box-shadow: var(--container-shadow);
  border-radius: var(--bdr-radius-lg);
}

.sectionContainer {
  max-width: 1978px;
  margin-inline: auto;
  padding: 30px;
}

@media screen and (max-width: 768px) {
  .sectionContainer {
    padding: 15px;
  }
}

body {

  font-family: var(--ff-roboto);
  /* margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
}

code {
  /* font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace; */
}
