.contactSection {
  background-color: var(--bg-main-primary);
}

.container {
  composes: flex-col from '../../index.css';
  composes: sectionContainer from '../../index.css';
  align-items: center;

  /* padding: 30px; */
  position: relative;
}

.contactBarContainer {
  position: absolute;
  top: 250px;
  left: 0px;
  z-index: 10;
  box-shadow: 0px 1px 5px 1px rgba(0,0,0,0.25);
}

/* .contactBody {
  width: 100%;
  composes: flex-col from '../../index.css';

  align-items: center;
} */

@media  screen and (max-width: 768px) {
  .contactBarContainer {
    display: none;
  }
}