.footer {
  background-color: var(--bg-header);
  min-height: 4.4rem;
  composes: flex-row from '../../index.css';
  /* align-items: center; */
}

.container {
  composes: flex-row from '../../index.css';
  composes: sectionContainer from '../../index.css';
  width: 100%;
}

.body {
  composes: flex-row from '../../index.css';
  justify-content: space-between;
  width: 100%;
  margin-inline: 20px;
}

.leftText, .rightText {
  composes: flex-row from '../../index.css';
  align-items: center;
}

@media screen and (max-width: 768px) {

  .footer {
    min-height: 3rem;
  }

  .leftText, .rightText {
    font-size: calc(var(--fz-xxs) + 0.5vw);
  }

}