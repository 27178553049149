
.projectSection {
  background-color: var(--bg-experience-section);
}

.container {
  composes: flex-col from '../../index.css';
  composes: sectionContainer from '../../index.css';
  align-items: center;
  padding-bottom: 200px;
}

.infoContainer {
  width: 100%;
  composes: flex-row-center from '../../index.css';
  flex-wrap: wrap;
  align-items: flex-start;
}

.infoContainer > * {
  margin-inline: 25px;
}

/* 
@media screen and (max-width: 768px) {
  .infoContainer > :nth-child(odd), .infoContainer > :nth-child(even) {
    margin-right: auto;
    margin-left: auto;
  }

  .infoContainer > :not(:last-child) {
    margin-bottom: 50px;
  }
} */