.container {
  composes: flex-row from '../../../index.css';
  justify-content: center;
  flex-wrap: wrap;

  padding: 10px;
}
.skill {
  composes: flex-col from '../../../index.css';
  width: 120px;
  box-shadow: 0.5px 0.5px 5px 0 rgb(0,0,0,0.25);
  border-radius: 3px;
  overflow: hidden;
  margin: 20px;
}

.contentBody {
  composes: flex-row from '../../../index.css';
  padding: 5px;
  height: 45px;
}

.footer {
  height: 10px;
  background-color: red;
}
