.skill {
  composes: flex-col from '../../../index.css';
  overflow: hidden;

  margin: 20px;
  width: 120px;
  border-radius: 3px;
  box-shadow: 0.5px 0.5px 5px 0 rgb(0,0,0,0.25);
}

.contentBody {
  composes: flex-row from '../../../index.css';
  justify-content: space-around;
  align-items: center;
  padding: 5px;
  height: 45px;
  font-size: var(--fz-md);
}

.icon {
  height: 1.8rem ;
}

.contentBody > * {
  padding: 2px;
}
.footer {
  background-color: red;

  height: 10px;
}

@media screen and (max-width: 768px) {
  .skill {
    margin: 5px;
  }
}

@media screen and (max-width: 550px) {
  .skill {
    width: 90px;
  }

  .icon {
    height: 1.2rem;
  }

  .contentBody {
    font-size: var(--fz-xs);
  }
}