.icon {
  color: var(--icon-clr-primary);
  padding: 5px;
}

/* .icon:hover {
  cursor: pointer;
} */

.withCursor:hover {
  cursor: pointer;
}