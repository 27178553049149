.container {
  /* composes: cardContainer from '../../index.css'; */
  composes: flex-row-center from '../../index.css';
  width: 500px;
  min-height: 350px;
  position: relative;
  margin-bottom: 50px;
}

.projectImage {
  composes: cardContainer from '../../index.css';
  height: 280px;
  width: 100%;
  overflow: hidden;
  position: absolute;
  top: 30px;
}

.projectImage img {
  width: 100%;
  height: 100%;
}

.projectInfo {
  composes: cardContainer from '../../index.css';
  background-color: var(--bg-exp-card-primary);
  min-height: 150px;
  width: 90%;
  padding: 5px;
  z-index: 10;
  margin-top: 265px;
  margin-bottom: 50px;
}

.projectHeader {
  composes: flex-row from '../../index.css';
  justify-content: space-between;
  align-items: center;

}
.projectTitle {
  padding: 5px;
  font-size: var(--fz-lg);
  font-weight: bold;
}

.projectLinks > :first-child {
  margin-right: 5px;
}

.projectDescription {
  padding: 5px;
  font-size: var(--fz-lg);
  margin-bottom: 1em;
  line-height: 1.5rem;
}

.technologies {
  composes: flex-row from '../../index.css';
  flex-wrap: wrap;
}

.technologies > * {
  margin: 5px;
}

@media screen and (max-width: 768px) {
  .container {
    width: 85%;
    margin-right: auto;
    margin-left: auto;
  }

  .projectImage {
    height: 200px;
  }
  .projectInfo {
    width: 95%;
    margin-top: 200px;
  }

  .projectDescription {
    font-size: var(--fz-md);
  }
}