
.experienceSection {
  background-color: var(--bg-experience-section);
}

.container {
  composes: flex-col from '../../index.css';
  composes: sectionContainer from '../../index.css';
  align-items: center;

  /* padding: 30px; */
  padding-bottom: 100px;
}

.infoContainer {
  width: 100%;
  composes: flex-col from '../../index.css';
  justify-content: center;
  align-items: center;
}

.infoContainer > :nth-child(odd) {
  margin-right: 30%;
}

.infoContainer > :nth-child(even) {
  margin-left: 30%;
}


.infoContainer > :not(:last-child) {
  margin-bottom: 100px;
}


@media screen and (max-width: 768px) {
  .infoContainer > :nth-child(odd), .infoContainer > :nth-child(even) {
    margin-right: auto;
    margin-left: auto;
  }

  .infoContainer > :not(:last-child) {
    margin-bottom: 50px;
  }
}