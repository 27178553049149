.tag {
  /* display: flex;
  flex-wrap: wrap;
  width: min-content; */
  display: inline-block;
  background-color: var(--bg-pill);
  border-radius: 1em;
}

.tag span {
  font-size: var(--fz-xs);
  display: inline-block;
  padding: 0.5em 2em;
}
