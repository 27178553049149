@media screen and (max-width: 768px) {
  .overlay {
    width: 100vw;
    height: 100vh;
    background-color: rgb(0, 0, 0, 0.25);
    top: 0;
    position: absolute;
    backdrop-filter: blur(7px);
    -webkit-backdrop-filter: blur(7px);
  }

  .mobileNavbarContainer {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    right: 0px;
    padding: 0.8rem;
    overflow: hidden;
  }

  .mobileNavbar {
    animation-duration: 500ms;
    animation-name: navbarSlide;
    /* margin-top: -100%; */

  }

  .navItem {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 2.8rem;
    background-color: var(--bg-header);
    border-radius: 1.4rem;
    min-width: 10rem;
    margin-bottom: 1rem;
  }

  .contactItems {
    margin: 0 1rem;
  }

  @keyframes navbarSlide {
    from {
      margin-top: -200%;
      /* height: 300%; */
    }

    to {
      margin-top: 0;
      /* height: 100%; */
    }
  }
}

